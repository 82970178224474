.heart-and-arrows-container, .excellent-cut-diamond-container, .commercial-melee-container {
  padding: 20px;
  max-width: 1200px;
  margin: 0 auto;
}

.heart-and-arrows-container h1, .excellent-cut-diamond-container h1, .commercial-melee-container h1 {
  font-size: 2.5em;
  color: #402b66;
  margin-bottom: 20px;
}

.heart-and-arrows-container p, .excellent-cut-diamond-container p, .commercial-melee-container p {
  font-size: 1.0em;
  line-height: 1.6;
  margin-bottom: 20px;
}

.heart-and-arrows-container h2, .excellent-cut-diamond-container h2, .commercial-melee-container h2 {
  font-size: 2em;
  color: #402b66;
  margin-top: 40px;
  margin-bottom: 20px;
}

.heart-and-arrows-container ul, .excellent-cut-diamond-container ul, .commercial-melee-container ul {
  list-style-type: none;
  padding: 0;
  color: #402b66;
}

.heart-and-arrows-container li, .excellent-cut-diamond-container li, .commercial-melee-container li {
  font-size: 1.2em;
  line-height: 1.6;
  margin-bottom: 10px;
}

.home-button-link {
  text-decoration: none;
}

.home-button {

  color: #402b66 !important;
  font-size: 16px;
  padding: 10px 20px;
  border-radius: 25px;
  transition: background-color 0.3s ease, transform 0.3s ease;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
}

.home-button:hover {
  /* background-color: #3700b3; */
  transform: scale(1.05);
}

.home-button:active {
  /* background-color: #3700b3; */
  transform: scale(0.95);
}

/* Responsive Layout */
.content-wrapper {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  margin-bottom: 20px;
  align-items: center;
  justify-content: center;
}

.image-container {
  flex: 1;
  min-width: 250px;
}

.image-container img {
  width: 100%;
  height: auto;
  border-radius: 10px;
  box-shadow: 0px 2px 6px 0px #ccc;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.image-container img:hover {
  transform: scale(1.05);
  box-shadow: 0px 4px 12px 0px #aaa;
}

.text-container {
  flex: 2;
  min-width: 300px;
  color: #402b66 !important;
}
/* src/Components/InquiryButton/InquiryButton.css */

.inquiry-button-container {
  text-align: center;
  display: contents;
}

.inquiry-button-link {
  text-decoration: none;
}

.inquiry-button {
  color: #402b66 !important;
  font-size: 16px;
  padding: 10px 20px;
  border-radius: 25px;
  transition: background-color 0.3s ease, transform 0.3s ease;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
  width: 200px;
  border: 1px solid #402b66;
}

.inquiry-button:hover {
  transform: scale(1.05);
}

.inquiry-button:active {
  
  transform: scale(0.95);
}


/* Responsive Styles */
@media (max-width: 768px) {
  .heart-and-arrows-container, .excellent-cut-diamond-container, .commercial-melee-container {
      padding: 10px;
  }

  .heart-and-arrows-container h1, .excellent-cut-diamond-container h1, .commercial-melee-container h1 {
      font-size: 2em;
  }

  .heart-and-arrows-container p, .excellent-cut-diamond-container p, .commercial-melee-container p {
      font-size: 1em;
  }

  .heart-and-arrows-container h2, .excellent-cut-diamond-container h2, .commercial-melee-container h2 {
      font-size: 1.5em;
  }

  .heart-and-arrows-container li, .excellent-cut-diamond-container li, .commercial-melee-container li {
      font-size: 1em;
  }
}

@media (max-width: 480px) {
  .heart-and-arrows-container, .excellent-cut-diamond-container, .commercial-melee-container {
      padding: 15px;
  }

  .heart-and-arrows-container h1, .excellent-cut-diamond-container h1, .commercial-melee-container h1 {
      font-size: 1.5em;
  }

  .heart-and-arrows-container p, .excellent-cut-diamond-container p, .commercial-melee-container p {
      font-size: 0.9em;
  }

  .heart-and-arrows-container h2, .excellent-cut-diamond-container h2, .commercial-melee-container h2 {
      font-size: 1.2em;
  }

  .heart-and-arrows-container li, .excellent-cut-diamond-container li, .commercial-melee-container li {
      font-size: 0.9em;
  }
  
}
