body {
    background-color: #f0eafb;
    /* width: fit-content; */
  }
  
  .Header {
    background-color: #fff;
    transition: top 0.3s ease-in-out;
    z-index: 1000;
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    width: 100%;
  }
  
  .Header img {
    height: 75px;
    width: 110px;
    padding-left: 30px;
  }
  
  .menubar {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
    padding-right: 60px;
  }
  
  .menubar.open {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 90px;
    left: 0;
    width: 100%;
    background-color: #fff;
    z-index: 1000;
    box-shadow: 0px 2px 4px 0px #bbb;
    padding: 20px 0;
  }
  
  .products-items,
  .diamonds-items {
    position: relative;
  }
  
  @media (max-width: 1080px) {

  
    .Header img {
      height: 60px;
      width: 90px;
    }
  
    .menubar {
      gap: 15px;
    }
  }
  
  @media (max-width: 768px) {
    .menubar {
      display: none;
      flex-direction: column;
      gap: 10px;
    }
  
    .menubar.open {
      display: flex;
    }
  
    .menubar a {
      width: 100%;
      text-align: center;
    }
  
    .Header img {
      height: 50px;
      width: 75px;
    }
  
  
  }
  