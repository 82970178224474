.perent{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .form-container {
    box-sizing: border-box;
    width: 100%;
    max-width: 1000px;
    margin: 70px auto;
    padding: 20px;
    margin: 50px 20px;
    background-color: #f0eafb;
    border-radius: 8px;
    border: 1px solid #402b66;
    height: auto;
    align-items: center;
    box-shadow: 0px 2px 5px #291e47;
    align-items: center;
    text-align: center;
  }
  
  .form-container h2 {
    color: #402b66;
    margin: 0;
  }
  
  .form-group {
    margin-bottom: 20px;
  }
  
  label {
    margin-bottom: 5px;
    color: #402b66;
    display: flex;
  }
  
  input,
  textarea {
    width: 100%;
    padding: 10px;
    border: 1px solid #402b66;
    border-radius: 4px;
    background-color: #f0eafb;
    box-sizing: border-box;
    margin-bottom: 10px;
  }
  
  button {
    display: block;
    width: 30%;
    padding: 10px;
    background-color: #402b66;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    font-family: "Nunito", sans-serif;
  }
  
  /* Define animation */
  @keyframes slideIn {
    from {
      transform: translateY(-20px);
      opacity: 0;
    }
  
    to {
      transform: translateY(0);
      opacity: 1;
    }
  }
  
  .animated-heading {
    position: relative;
    cursor: pointer;
    transition: color 0.3s ease, transform 0.3s ease;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .animated-heading:hover {
    color: #402b66;
    /* Change text color on hover */
    transform: scale(1.1);
    /* Scale up slightly on hover */
    text-shadow: 0px 2px 5px #402b66;
    border-radius: 4px;
  }
  
  /* Add cool hover effect to the submit button */
  button {
    display: block;
    padding: 10px;
    background-color: #402b66;
    color: #fff;
    border: none;
    border-radius: 4px;
    align-items: center;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.3s ease;
  }
  
  button:hover { 
    transform: scale(1.05);
    /* Grow slightly on hover */
    background-color: #f0eafb;
    color: #291e47;
    border: 0.11px solid #402b66;
  }
  
  .submit {
    align-items: center;
    display: flex;
    justify-content: center;
  }
  .back-button-container {
    margin: 20px;
  }
  
  .back-button {
    display: flex;
    align-items: center;
    width: 200px;
    color: #402b66 !important;
    padding: 10px 20px;
    border-radius: 25px;
    transition: background-color 0.3s ease, transform 0.3s ease;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    text-decoration: none;
  }
  
  .back-button:hover {
    background-color: #3700b3;
    transform: scale(1.05);
  }
  
  .back-button:active {
    background-color: #3700b3;
    transform: scale(0.95);
  }
  
  .back-button svg {
    margin-right: 8px;
  }
 