.expertise {
    margin: 20px;
    border: 0px solid;
    border-radius: 10px;
    box-shadow: 0px 2px 3px 0px #402b66;
    padding: 20px;
}

.expertise h2 {
    color: #402b66;
    font-size: 24px;
    position: relative;
    cursor: pointer;
    margin-bottom: 20px; 
    display: flex;
    align-items: center;
    justify-content: center;
}
.expertise h2:hover{
    animation: pulse 2s infinite ease-in-out;
}
@keyframes pulse {
    0% {
        transform: scale(1); /* Initial scale */
        text-shadow: none; /* No text shadow */
    }
    50% {
        transform: scale(1.1); /* Scale up slightly */
        text-shadow: 0px 3px 6px #402b66; /* Apply text shadow */
    }
    100% {
        transform: scale(1); /* Return to initial scale */
        text-shadow: none; /* Remove text shadow */
    }
}

.expertise-text {
    margin-bottom: 20px;
    color: #402b66;
}

.expertise-text p {
    width: auto;
    line-height: 1.6;
}
