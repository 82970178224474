body{
    padding: 0;
    margin: 0;
}
.banner-text {
    /* position: absolute; */
    right: 50%;
    left: 60%;
    top: 30%;
    width: 700px;
    color: rgb(219, 229, 255);
    animation: slideIn 1s forwards; /* Apply slideIn animation */
}

.banner-text h1 {
    font-family: "Cinzel", serif;
    font-optical-sizing: auto;
    font-size: 35px;
    font-style: normal;
    animation: fadeInUp 1s forwards; /* Apply fadeInUp animation */
}

.banner-text h3 {
    font-size: 25px;
    font-family: "Cinzel", serif;
    font-optical-sizing: auto;
    font-style: normal;
    animation: fadeInUp 1s forwards; /* Apply fadeInUp animation */
}
.rounddiamond h1{
  display: flex;
  justify-content: center;
  align-items: center;
}

@keyframes slideIn {
    from {
        opacity: 0;
        transform: translateX(-100px); /* Start from left side */
    }
    to {
        opacity: 1;
        transform: translateX(0); /* Move to original position */
    }
}

@keyframes fadeInUp {
    from {
        opacity: 0;
        transform: translateY(20px); /* Start slightly below */
    }
    to {
        opacity: 1;
        transform: translateY(0); /* Move to original position */
    }
}
.title-image{
    display: flex;
    align-items: center;
    gap: 10px;
    justify-content: center;
    width: 100%;
    color: #402b66;
}
.title-image h2{
    font-size: 30px;
}

.title-image img{
    height: 30px;
    width: 30px;    
}
.title-text {
    color: #402b66;
    width: auto;
    font-size: 20px;
    margin: 0 auto; 
    text-align: center;
  }
  .sizes h2{
    font-size: 30px;
    color: #402b66;
  }
  .sizes {
    color: #402b66;
    font-size: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .size1,.size2,.size3,.size4{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    height: 200px;
    width: 150px;
  }

  
  .size1 img{
    height: 50px;
    width: 50px;
  }
  .size2 img{
    height: 70px;
    width: 70px;
  }
  .size3 img{
    height: 90px;
    width: 90px;
  }.size4 img{
    height: 110px;
    width: 110px;
  }

  .size-list{
    display: flex;
    gap: 30px;
    align-items: center;
    justify-content: center;
    color: #402b66;
  }
  .container {
    display: flex;
    justify-content: center; /* Center the line horizontally */
  }
  
  .horizontal-line {
    border-bottom: 1.5px solid #999999; /* Adjust color and thickness as needed */
    width: 60%; /* Set the desired width of the line */
    margin-top: 40px;
    margin-top: 40px;
    /* box-shadow: 2px 1px 2px 0px #000000; */
  }
.color{
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 20px;
    color: #402b66;
}
.color h2{
    font-size: 30px;
    color: #402b66;
}
.color svg{
    margin: 20px;
}
@media (max-width: 760px) {
  .size-list{
    display: flex;
    gap: 30px;
    align-items: center;
    justify-content: center;
    color: #606060;
    flex-direction: column;
  }
}