.crafts {
  margin-top: 50px;
  background-color: #402b66;
  background-size: cover;
}

.craft-videos {
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  height: auto;
}

.craft-videos>div {
  width: calc(20% - 20px);
  margin: 10px;
  padding: 15px;
  padding-right: 20px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
}

.craft-videos>div img {
  height: 200px;
  width: 80%;
  border-radius: 10px;
}

.crafts h1 {
  color: #d4bdff;
  font-weight: 400;
  font-size: 2rem;
}

.crafts p {
  color: rgb(240, 220, 255);
  max-width: 1000px;
  font-size: 25px;
  text-align: center;
}

.crafts-text {
  width: 100%;
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  opacity: 0;
  animation: slideInUp 1s ease forwards;
}

@keyframes slideInUp {
  0% {
    opacity: 0;
    transform: translateY(50px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.crafts-icons {
  perspective: 1000px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  padding: 20PX;
  column-gap: 30px;
  animation: slideInUp 1s ease forwards;
}

.crafts-icons img {
  padding: 10px;
  background-color: #d4bdff;
  border-radius: 12px;
  height: 50px;
  width: 50px;
  transition: transform 0.3s;
}

.crafts-icons img:hover {
  transform: translateY(-5px) scale(1.1);
}

@media (max-width: 767px) {
  .craft-videos>div {
    width: 100%;
    
  }
  .craft-videos>div img {
    height: 300px;
    border-radius: 15px;
  }

  .crafts h1 {
    font-size: 2rem;
  }

  .crafts p {
    font-size: 1.2rem;
    padding: 20px;
  }
}
@media (max-width:460px){
  .crafts-icons img{
  height: 30px;
  width: 30px;
  }
  .crafts-icons{
    padding: 10px;
  }
}