.management-team {
  text-align: center;
  color: #402b66;
  user-select: none;
}

.management-team h1 {
  margin: 20px;
}

.team-member{
  display: flex !important;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.team-member img {
  width: 300px;
  height: 350px;
  border-radius: 10px;
  box-shadow: 0px 3px 6px #d4bdff;
  border: 0.5px solid #402b66;
}

.card-content{
  align-items: center;
  text-align: center;
}
@media (max-width: 760px) {
  .team-member img {
    width: 200px;
    height: 250px;
  }
}