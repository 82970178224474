.about h2 {
    font-size: 15px;
    max-width: 250px;
    text-align: justify;
    justify-content: center;
    line-height: 1.5;
    display: flex;
    color: #402b66;
    margin: 10px;

}

.about h1 {
    font-size: 20px;
    max-width: 200px;
    display: flex;
    column-gap: 10px;
    align-items: center;
    justify-content: center;
    color: #402b66;
    margin: 10px;
}

.Footer {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.quick-links {
    display: flex;
    align-items: flex-start;
    color: #402b66;
    flex-direction: column;
}

.quick-links h1 {
    font-size: 20px;
    max-width: 200px;
    display: flex;
    column-gap: 10px;
    align-items: center;
    justify-content: center;
    color: #402b66;
    margin: 10px;

}
.quick-links h3{
    cursor: pointer;
}
.quick-links a{
    text-decoration: none;
}

.quick-title {
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 10px;
}

.quick-links h3 {
    display: flex;
    align-items: flex-start;
    color: #402b66;
    margin: 10px;
    font-size: 15px;
}

.social-share h4 {
    font-family: "Nunito", sans-serif;
    color: #402b66;
    margin: 0;
}

.social-share {
    display: flex;
    justify-content: space-between;
    padding: 20px;
    background-color: #f0eafb;
    border-bottom: 0.5px solid #402b66;
    border-top: 0.5px solid #402b66;
}

.social-icons-share {
    size: 50px;
    gap: 20px;
    align-items: center;
    display: flex;
    justify-content: flex-start;
    color: #402b66;
    cursor: pointer;

}

.social-icons-share a {
    text-decoration: none;
    color: inherit;
}

.social-icons {
    size: 50px;
    gap: 20px;
    align-items: center;
    display: flex;
    justify-content: flex-start;
}

.footer-content {
    display: flex;
    justify-content: space-around;
}

.products {
    display: flex;
    align-items: flex-start;
    color: #402b66;
    flex-direction: column;
    
}

.products h1 {
    font-size: 20px;
    max-width: 200px;
    display: flex;
    column-gap: 10px;
    align-items: center;
    justify-content: center;
    color: #402b66;
    margin: 10px;
  

}

.products h3 {
    display: flex;
    align-items: flex-start;
    color: #402b66;
    margin: 10px;
    font-size: 15px;
    cursor: pointer;
}

.products a {
    text-decoration: none;  
}
.links a{
    text-decoration: none;  
}

.contact-us h5 {
    max-width: 300px;
    line-height: 1.5;
    margin: 0;
    text-align: justify;
    color: #402b66;
}

.contact-us h1 {
    font-size: 20px;
    max-width: 200px;
    display: flex;
    column-gap: 10px;
    align-items: center;
    color: #402b66;
    margin: 10px;

}

.contact-us h4 {
    margin-left: 20px;
    font-size: 20px;
    /* max-width: 200px; */
    display: flex;
    column-gap: 10px;
    align-items: center;
    justify-content: flex-start;
    color: #402b66;
    margin: 0;
}

.contact-us {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}

.contact-us h3 {
    column-gap: 10px;
    margin: 10px;
    column-gap: 10px;
}

.contact-us h2 {
    font-size: 20px;
    max-width: 300px;
    display: flex;
    column-gap: 10px;
    align-items: center;
    justify-content: center;
    color: #402b66;
    margin: 10px;
}

.social {
    display: flex;
    margin: 10px;
    gap: 10px;
}

.social svg {
    color: #402b66;
    width: 30px;
    height: 30px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}
.copyrights{
    color: #402b66;
    display: flex;
    align-items: center;
    justify-content: center;
    border-top: 1px solid #402b66;
    width: auto;
}

@media (max-width: 760px) {
    .footer-content {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
    }

    .social-share {
        flex-direction: column;
        align-items: center;
        text-align: center;
        gap: 20px;
    }

    .about h1 {
        padding-top: 10px;
        margin: auto;
    }

    .about h2 {
        max-width: 500px;
        text-align: justify;
    }

    .products {
        border-top: 1px solid #402b66;
        width: 100%;
        align-items: center;
        text-align: center;
    }

    .quick-links {
        border-top: 1px solid #402b66;
        width: 100%;
        align-items: center;
        text-align: center;
    }

    .links {
        display: flex;
        align-items: flex-start;
        color: #402b66;
        flex-direction: column;
    }

    .links>h3 {
        padding: 10px;
        margin: auto;
    }

    .contact-us {
        border-top: 1px solid #402b66;
        width: 100%;
        align-items: center;
        text-align: center;
    }
}