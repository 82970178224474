/* Navbar.css */

.Navbar {
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding: 10px 20px;
}

.Navbar img {
  height: 75px;
  width: 110px;
  margin-left: -200px;
}

.navbar ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  text-align: center;
  align-items: center;
  justify-content: center;
  display: flex;
  padding: 10px;
}

.navbar ul li {
  display: inline-block;
  margin-right: 20px;
}

.navbar ul li:last-child {
  margin-right: 0;
}

.navbar ul li a {
  color: #402b66;
  text-decoration: none;
  padding: 5px 10px;
}

.navbar {
  position: fixed;
  top: -100px; /* Initially hidden */
  left: 0;
  width: 100%;
  background-color: #fff;
  transition: top 0.3s ease, opacity 0.3s ease;
  z-index: 1000;
  opacity: 0; /* Initially hidden */
}

.navbar.sticky {
  top: 0;
  opacity: 1; /* Fully visible when sticky */
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.navbar-placeholder {
  display: none; /* Initially hidden */
}

.navbar-placeholder.active {
  display: block; /* Shown when the navbar is sticky */
  height: 100px; /* Match the height of the navbar */
}

.cool-button {
  font-family: "Nunito", sans-serif;
  font-size: 16px;
  padding: 10px 20px;
  border-radius: 25px;
  transition: background-color 0.3s ease, transform 0.3s ease;
  width: 100px;
  margin-left: auto;
}

.cool-button:hover {
  transform: scale(1.05);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  border: none;
}

.cool-button:active {
  transform: scale(0.95);
}

.custom-menu .MuiPaper-root {
  background-color: #6200ea !important;
  color: #f0eafb !important;
  border-radius: 10px !important;
}

.custom-menu-item {
  color: #402b66 !important;
}

.custom-menu-item:hover {
  background-color: #f0eafb !important;
}

.horizontal-menu {
  display: flex;
  width: 900px;
}

.Menubar {
  display: flex;
  align-items: center;
}

.menu-item {
  color: #402b66 !important;
  font-family: "Nunito", sans-serif !important;
  padding: 0;
  text-decoration: none;
}

.hamburger-menu {
  display: none;
  flex-direction: column;
  cursor: pointer;
}

.menus {
  display: flex;
  justify-content: space-around;
  align-items: center;
  gap: 55px;
}

.menu-item {
  margin: 0 10px;
}

.menu-item:hover {
  text-decoration: underline !important;
  border: none;
}

.hamburger-menu .bar {
  width: 25px;
  height: 3px;
  background-color: #402b66;
  margin: 4px 0;
  transition: all 0.3s ease;
  border-radius: 15px;
}

.bar.open:nth-child(1) {
  transform: translateY(15px) rotate(-45deg);
}

.bar.open:nth-child(2) {
  opacity: 0;
}

.bar.open:nth-child(3) {
  transform: translateY(-7px) rotate(45deg);
}
@media (max-width: 1660px){
  .Navbar img{
    margin-left: -150px;
  }
}

@media (max-width: 1440px) {
  .horizontal-menu {
    display: none;
  }

  .hamburger-menu {
    display: flex;
  }

  
  .menus {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
    flex-direction: column;
  }

  .horizontal-menu.open {
    display: flex;
        flex-direction: column;
        position: absolute;
        top: 90px;
        left: 0;
        height: 400px;
        width: 100%;
        background-color: #f0eafb;
        padding: 20px;
        box-shadow: 0 3px 6px rgba(0, 0, 0, 0.3);
        z-index: 1000;
        align-items: center;
        justify-content: center;
  }

  .horizontal-menu.open .menu-item {
    margin-bottom: 10px;
  }
  .nav-menus{
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: center;

  }

  .horizontal-menu.open .menu-item {
    margin-bottom: 10px;
  }
}

@media (max-width: 767px) {
  .horizontal-menu {
    display: none;
  }

  .hamburger-menu {
    display: flex;
  }
  .Navbar img {
    height: 75px;
    margin-left: -100px;
   
  }

  .menus {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
    flex-direction: column;
  }

  .horizontal-menu.open {
    display: flex;
        flex-direction: column;
        position: absolute;
        top: 90px;
        left: 0;
        height: 400px;
        width: 100%;
        background-color: #f0eafb;
        padding: 20px;
        box-shadow: 0 3px 6px rgba(0, 0, 0, 0.3);
        z-index: 1000;
        align-items: center;
        justify-content: center;
  }

  .horizontal-menu.open .menu-item {
    margin-bottom: 10px;
  }
  .nav-menus{
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: center;

  }
}

@media (max-width: 460px) {
  .horizontal-menu {
    display: none;
  }

  .hamburger-menu {
    display: flex;
  }
  .Navbar img {
    height: 75px;
    margin-left: -50px;
   
  }

  .menus {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 1200px;
    flex-direction: column;
  }

  .horizontal-menu.open {
    display: flex;
        flex-direction: column;
        position: absolute;
        top: 90px;
        left: 0;
        height: 400px;
        width: 100%;
        background-color: #f0eafb;
        padding: 20px;
        box-shadow: 0 3px 6px rgba(0, 0, 0, 0.3);
        z-index: 1000;
        align-items: center;
        justify-content: center;
  }

  .horizontal-menu.open .menu-item {
    margin-bottom: 10px;
  }
}
