.ourpeople {
  justify-content: space-between;
  display: flex;
  align-items: center;
  gap: 20px;
  padding: 20px;
}
#our-people{
  scroll-behavior: smooth;
}
.Our-people-video{
  width: 600px;
  border-radius: 0px 65px 0px 65px;
  box-shadow: 0px 3px 6px 0px #402b66;
  user-select: none;
}
.ourpeople > div{
  flex: 1 1;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.ourpeople img {
  max-width: 100%;
  height: 100%;
  width: 800px;
  animation: fadeIn 2s ease-in-out;
  box-shadow: 0 5px 16px #402b66;
  border-radius: 50px 0px 50px 0px;
  transition: transform 0.3s ease;
  user-select: none;
}
.Our-Img{
  pointer-events: none;
  -webkit-user-drag: none;
  user-select: none;
}

.ourpeople img:hover {
  transform: scale(1.05);
}

.our-text {
  flex: 0.5;
  text-align: center;
  padding: 20px;
}

.our-text h1 {
  color: #402b66;
  font-size: 24px;
}

.our-text p {
  color: #402b66;
  text-align: justify;
  max-width: 600px;
}

.counting-container .conting-text {
  font-size: 48px;
  color: #402b66;
  opacity: 0.7;
  padding: 10px 20px;
  border-radius: 5px;
  margin-right: 10px;
  font-family: "Rubik Scribble", system-ui;
  user-select: none;
  text-align: center;
}

.counting-container {
  display: flex;
}


/* General Styles */
.ourpeople {
  display: flex;

}

.our-text {
  max-width: 100%;
  text-align: center;
}

.our-text .conting-text {
  font-size: 2rem;
}

.our-text p {
  font-size: 1.2rem;
}

.Our-people-video {
  /* width: 100%; */
  /* max-width: 100%; */
  user-select: none;
}

/* Styles for devices with a maximum width of 767px */
@media (max-width: 767px) {
  .ourpeople {
    display: flex;
    flex-direction: column-reverse;
  }

  .our-text {
    max-width: 100%;
    text-align: center;
  }

  .our-text .conting-text {
    font-size: 2rem;
  }

  .our-text p {
    font-size: 1.2rem;
  }

  .Our-people-video {
    width: 100%;
    max-width: 500px;
    user-select: none;
  }
}

/* Styles for devices with a maximum width of 520px */
@media (max-width: 520px) {
  .Our-people-video {
    width: 100%;
    max-width: 400px;
  }
}
