.home {
  position: relative;
  /* width: 100vw; */
  height: 100vh;
  overflow: hidden;
  font-family: 'Nunito', sans-serif;
}

.background-video {
  position: relative;
  width: 100vw;
  height: 100vh;
  object-fit: cover;
  opacity: 0.5;
  user-select: none;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.overlay-text {
  color: #fff;
  text-align: center;
}

.overlay-text h1 {
  font-size: 3rem;
  margin-bottom: 20px;
}

.overlay-text p {
  font-size: 1.5rem;
}

.overlay img {
  width: 100%;
  max-width: 600px;
  height: auto;
  user-select: none;
}

@media (max-width: 767px) {
  .background-video {
    width: 100%;
    height: 100%;
    user-select: none;
  }

  .overlay-text h1 {
    font-size: 2rem;
  }

  .overlay-text p {
    font-size: 1.2rem;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {

  .background-video {
    width: 100%;
    height: 100%;
    user-select: none;
  }

  .overlay-text h1 {
    font-size: 2.5rem;
  }

  .overlay-text p {
    font-size: 1.4rem;
  }
}