.reputation {
    margin: 20px;
    border: 0px solid;
    border-radius: 10px;
    box-shadow: 0px 2px 3px 0px #402b66;
    padding: 20px;
}

.reputation h2 {
    color: #402b66;
    font-size: 24px;
    position: relative;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
   
    margin-bottom: 20px;
}
.reputation h2:hover{
    animation: pulse 2s infinite ease-in-out;
}

@keyframes pulse {
    0% {
        transform: scale(1); /* Initial scale */
        text-shadow: none; /* No text shadow */
    }
    50% {
        transform: scale(1.1); /* Scale up slightly */
        text-shadow: 0px 3px 6px #402b66; /* Apply text shadow */
    }
    100% {
        transform: scale(1); /* Return to initial scale */
        text-shadow: none; /* Remove text shadow */
    }
}

.reputation-text {
    margin-bottom: 20px;
    color: #402b66;
}

.reputation-text p {
    width: auto;
    line-height: 1.6;
}
/* .mouse-pointer {
    position: absolute;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    border: 0px solid #402b66;
    opacity: 0.5;
    box-shadow: 0px 4px 10px 0px #402b66;
    z-index: 9999;
    cursor: pointer;
    transform: translate(-50%, -50%);
} */