body{
    font-family: "Nunito", sans-serif;
}
.about-us {
    width: 100%;
    display: flex;
   
    background-color: #f0eafb;
    justify-content: space-around;
    flex-direction: column;
    align-items: center;
    text-align: justify;
}
.about-section h3{
    color: #240c50;
}

.about-us-text {
    padding: 20px;
    color: #402b66;
}

.about-section a {
    text-decoration: none;
    color: inherit;
}

.about-four {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}

.about-first-row {
    display: flex;
    justify-content: space-between;
}

.about-section {
    flex: 0.5;
    background-color: f0eafb;
    border-radius: 5px;
    border: 0.5px solid #240c50;
    box-shadow: 0px 0px 3px 1px #d4bdff;
    padding: 20px;
    margin: 20px;
    transition: transform 0.3s ease-in-out;
    align-items: center;
    text-align: center;
}

.about-section p{
    text-align: justify;
    color: #402b66;
}

.about-section svg {
    max-width: 100px;
    height: auto;
    transition: transform 0.3s ease-in-out;
}

.about-section:hover svg {
    transform: rotate(360deg);
}

.about-us-text h1 {
    font-size: 24px;
    margin-bottom: 10px;
    color: #402b66;
    user-select: none;
    animation: fadeInUp 1s forwards;
    text-align: center;
    color: #402b66;
}

@media (max-width: 767px) {
    .about-first-row {
        flex-direction: column;
    }

    .about-section {
        flex: 1;
    }
}